import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AutocompleteElement,
  SelectElement,
  SwitchElement,
  TextFieldElement,
  useFieldArray,
  useFormContext,
} from 'react-hook-form-mui';
import { Link } from 'react-router-dom';

import {
  Add,
  AttachFile,
  Check,
  Close,
  Delete,
  Edit,
  Link as LinkIcon,
  Visibility,
} from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  DialogProps,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputBase,
  Pagination,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';

import Switch from '../../../components/switch';
import { getPaginatedItems } from '../../../helpers/paginated';
import Validator from '../../../libraries/validator';
import resources from '../../../resources';
import { ICenter } from '../../../resources/notification';
import ImageInput from '../../content/components/image-input';

const USER_TYPE_OPTIONS = [
  {
    label: 'Todos',
    id: 'all',
  },
  {
    label: 'Produtores',
    id: 'provider',
  },
  {
    label: 'Colaboradores',
    id: 'collaborator',
  },
  {
    label: 'Supervisores',
    id: 'supervisor',
  },
];

export const schemaValidation = Validator.object().shape({
  push: Validator.object().shape({
    enabled: Validator.boolean()
      .default(false)
      .required()
      .label('Notificaçao Push'),
  }),
  important: Validator.object().shape({
    enabled: Validator.boolean().default(false).label('Alerta Importante'),
    type: Validator.string()
      .default('text')
      .when('enabled', {
        is: (enabled: boolean) => !!enabled,
        then: (schema) => schema.required(),
      })
      .label('Tipo'),
    image: Validator.mixed().when(['type'], {
      is: (type: 'image' | 'text') => type === 'image',
      then: (schema) => schema.required(),
    }),
    ok: Validator.object()
      .when('enabled', {
        is: (enabled: boolean) => !!enabled,
        then: (schema) => schema.required(),
      })
      .shape({
        label: Validator.string().default('OK').label('Etiqueta do Botão'),
        link: Validator.string().url().label('Link'),
      }),
    cancel: Validator.object().shape({
      label: Validator.mixed()
        .nullable()
        .default('Cancelar')
        .label('Etiqueta do Botão'),
      link: Validator.string().url().nullable().label('Link'),
    }),
  }),
  // centers: Validator.array()
  //   .default([])
  //   .min(1)
  //   .of(
  //     Validator.object().shape({
  //       id: Validator.string().required(),
  //       label: Validator.string().required(),
  //     })
  //   )
  //   .label('Centros'),
  // userType: Validator.string().required().label('Destinatários'),
  title: Validator.string().required().label('Titulo'),
  recipients: Validator.array()
    .default([])
    .min(1)
    .of(
      Validator.object().shape({
        id: Validator.string().required(),
        name: Validator.string().required(),
        type: Validator.string().required(),
      })
    ),
  message: Validator.string()
    .when(['important.enabled', 'important.type'], {
      is: (importantAlert: boolean, importantAlertType: 'text' | 'image') =>
        !importantAlert || (importantAlert && importantAlertType === 'text'),
      then: (schema) => schema.required(),
    })
    .label('Mensagem'),
  url: Validator.string().url().label('URL'),
  attachment: Validator.mixed().label('Anexo'),
});

interface IRecipient {
  id: string;
  name: string;
  type: string;
}
interface IInputButtonControl {
  defaultValues?: Partial<{
    label: string;
    link: string;
  }>;
  disabledDelete?: boolean;
  names: {
    label: string;
    link: string;
  };
}

const InputButtonControl: React.FC<IInputButtonControl> = ({
  names,
  defaultValues,
  disabledDelete = true,
}) => {
  const form = useFormContext();

  const inputLabelRef = useRef<any>();
  const inputLinkRef = useRef<any>();

  const [stateButton, setStateButton] = useState<
    'edit' | 'read' | 'deleted' | 'edit-link' | 'deleted-link'
  >('read');

  const [label, setLabel] = useState<string | undefined>(
    () => defaultValues?.label
  );
  const [link, setLink] = useState<string | undefined>(
    () => defaultValues?.link
  );

  const isValidLink = useMemo(() => {
    if (!link) return false;
    try {
      return !!new URL(link);
    } catch {
      return false;
    }
  }, [link]);

  return (
    <Box>
      <Collapse
        in={stateButton !== 'deleted'}
        unmountOnExit
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <Collapse
            in={stateButton === 'edit-link'}
            orientation="horizontal"
            unmountOnExit
          >
            <InputBase
              inputRef={inputLinkRef}
              onChange={(event) => {
                const value = event?.target?.value;
                setLink(!isEmpty(value) ? value : undefined);
              }}
              placeholder="http://"
              readOnly={stateButton === 'read'}
              sx={(theme) => ({
                px: 1,
                flex: 1.5,
                bgcolor: stateButton === 'read' ? 'grey.100' : 'transparent',
                border:
                  stateButton === 'edit-link'
                    ? `1px solid ${theme.palette.grey[100]}`
                    : undefined,
                borderRadius: theme.shape.borderRadius,
              })}
              value={link ?? ''}
            />
          </Collapse>
          <Collapse
            in={stateButton !== 'edit-link'}
            orientation="horizontal"
            unmountOnExit
          >
            <InputBase
              inputRef={inputLabelRef}
              onChange={(event) => {
                const value = event?.target?.value;
                setLabel(!isEmpty(value) ? value : undefined);
              }}
              readOnly={stateButton === 'read'}
              sx={(theme) => ({
                px: 1,
                flex: 1.5,
                bgcolor: stateButton === 'read' ? 'grey.100' : 'transparent',
                border:
                  stateButton === 'edit'
                    ? `1px solid ${theme.palette.grey[100]}`
                    : undefined,
                borderRadius: theme.shape.borderRadius,
                '.MuiInputBase-input': {
                  textAlign: 'center',
                },
              })}
              value={label ?? ''}
            />
          </Collapse>
          <Stack
            alignItems="center"
            direction="row"
            sx={{ flex: 1 }}
          >
            <IconButton
              color={
                ['edit', 'edit-link'].includes(stateButton)
                  ? 'success'
                  : 'default'
              }
              disabled={
                (!isValidLink && stateButton === 'edit-link') ||
                (stateButton === 'edit' && isEmpty(label))
              }
              onClick={() => {
                if (stateButton === 'edit' || stateButton === 'edit-link') {
                  switch (stateButton) {
                    case 'edit': {
                      form.setValue(names.label, label);
                      break;
                    }
                    case 'edit-link': {
                      form.setValue(names.link, link);
                      break;
                    }
                  }
                }

                if (stateButton === 'deleted-link') {
                  form.setValue(names.link, undefined);
                }

                setStateButton(() =>
                  ['edit', 'edit-link'].includes(stateButton) ? 'read' : 'edit'
                );
                if (stateButton === 'read') {
                  inputLinkRef.current?.focus();
                }
              }}
            >
              {!['edit', 'edit-link'].includes(stateButton) ? (
                <Edit />
              ) : (
                <Check />
              )}
            </IconButton>
            {['edit'].includes(stateButton) && (
              <IconButton
                color="error"
                onClick={() => {
                  form.setValue(names.label, undefined);
                  setLabel(defaultValues?.label);
                  setStateButton(() => 'read');
                }}
              >
                <Close />
              </IconButton>
            )}
            {['edit-link'].includes(stateButton) && (
              <IconButton
                color="error"
                onClick={() => {
                  form.setValue(names.link, undefined);
                  setLink(defaultValues?.link);

                  setStateButton(() => 'read');
                }}
              >
                <Close />
              </IconButton>
            )}
            <Collapse
              in={!['edit-link', 'edit'].includes(stateButton)}
              unmountOnExit
            >
              <IconButton
                color={stateButton === 'edit' ? 'success' : 'default'}
                onClick={() => {
                  setStateButton(() =>
                    stateButton === 'edit-link' ? 'read' : 'edit-link'
                  );
                  if (stateButton === 'read') {
                    inputLabelRef.current?.focus();
                  }
                }}
              >
                <LinkIcon />
              </IconButton>
            </Collapse>
            {!!disabledDelete && (
              <Collapse
                in={stateButton === 'read'}
                orientation="horizontal"
                unmountOnExit
              >
                <IconButton
                  onClick={() => {
                    setStateButton(() => 'deleted');
                    form.setValue(names.label, false);
                    form.setValue(names.link, null);
                    setLink(undefined);
                    setLabel(undefined);
                  }}
                >
                  <Delete />
                </IconButton>
              </Collapse>
            )}
          </Stack>
        </Stack>
      </Collapse>
      <Collapse
        in={stateButton === 'deleted'}
        unmountOnExit
      >
        <Button
          fullWidth
          onClick={() => {
            setStateButton(() => 'read');
            setLabel(defaultValues?.label);
            setLink(defaultValues?.link);
            form.setValue(names.label, defaultValues?.label);
            form.setValue(names.link, defaultValues?.link);
          }}
          startIcon={<Add />}
          sx={(theme) => ({
            borderColor: theme.palette.grey[300],
            color: theme.palette.grey[500],
            borderStyle: 'dashed!important',
          })}
          variant="outlined"
        >
          Adicionar Botão
        </Button>
      </Collapse>
    </Box>
  );
};

const ImportantAlertPreview: React.FC<{ dialogProps: DialogProps }> = ({
  dialogProps,
}) => {
  const form = useFormContext();

  const values = form.watch();

  const imagePreview = useMemo(() => {
    if (values.important.image) {
      return URL.createObjectURL(values.important.image);
    }
    return undefined;
  }, [values.important.image]);

  const visibleCancelButton = useMemo(() => {
    if (typeof values.important?.cancel?.label === 'boolean') {
      return !!values.important?.cancel?.label;
    }
    if (typeof values.important?.cancel?.label === 'string') {
      return true;
    }
    return false;
  }, [values.important?.cancel?.label]);

  return (
    <Dialog
      {...dialogProps}
      fullWidth
      maxWidth="xs"
    >
      {(values.important?.type ?? 'text') === 'text' && (
        <DialogContent sx={{ p: 5 }}>
          <Stack spacing={3}>
            <Typography
              sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}
            >
              {values.title}
            </Typography>
            <Typography sx={{ color: 'grey.500', textAlign: 'center' }}>
              {values.message}
            </Typography>
          </Stack>
        </DialogContent>
      )}
      {values.important?.type === 'image' && !!imagePreview && (
        <Box
          component="img"
          src={imagePreview}
          sx={{ height: '70vh' }}
        />
      )}
      <Divider />
      <Stack direction="row">
        {visibleCancelButton && (
          <>
            <ButtonBase
              component="a"
              href={
                typeof values.important?.cancel?.link === 'string'
                  ? values.important?.cancel?.link
                  : undefined
              }
              sx={{ flex: 1, p: 2, whiteSpace: 'nowrap' }}
              target="_blank"
            >
              {values.important?.cancel?.label ?? 'Cancelar'}
            </ButtonBase>
            <Divider
              flexItem
              orientation="vertical"
            />
          </>
        )}
        <ButtonBase
          component="a"
          href={
            typeof values.important?.ok?.link === 'string'
              ? values.important?.ok?.link
              : undefined
          }
          sx={{ flex: 1, p: 2, whiteSpace: 'nowrap' }}
          target="_blank"
        >
          {values.important?.ok?.label ?? 'OK'}
        </ButtonBase>
      </Stack>
    </Dialog>
  );
};

const NotificationForm: React.FC = () => {
  const [page, setPage] = useState(1);
  const form = useFormContext();

  const recipientForm = useFieldArray({
    control: form.control,
    name: 'recipients',
  });

  const values = form.watch();
  const centerOptions: { id: string; label: string }[] =
    form.watch('centers') ?? [];
  const [centers, setCenters] = useState<ICenter[]>([]);

  const [recipients, setRecipients] = useState<IRecipient[]>([]);

  const [openImportantAlertPreview, setOpenImportantAlertPreview] =
    useState(false);

  const recipientsPaginated = useMemo(
    () => getPaginatedItems<IRecipient>(recipients, page, 10),
    [page, recipients]
  );

  const setAllRecipients = useCallback(() => {
    form.setValue('recipients', recipients);
  }, [recipients, form]);

  const getCenters = useCallback(async () => {
    const response = await resources.use('property').getCenters();

    if (response.status === 'OK') {
      setCenters(
        response?.payload
          ? (response.payload ?? []).map(({ codCenter }) => ({
              id: codCenter,
              label: codCenter,
            }))
          : []
      );
    }
  }, []);

  const getRecipients = useCallback(async () => {
    const response = await resources.use('user').notificationUsers({
      centers: (values.centers ?? []).some(
        (c: { id: string }) => c.id === 'all'
      )
        ? centers.map((c) => c.id)
        : (values.centers ?? []).map(({ id }: { id: string }) => id),
      userType:
        values.userType !== 'all'
          ? [values.userType]
          : USER_TYPE_OPTIONS.map((u) => u.id),
    });

    if (response.status === 'OK') {
      setRecipients(
        response?.payload
          ? (response.payload ?? []).map(({ id, name, typeUser }) => ({
              id,
              name,
              type: typeUser,
            }))
          : []
      );
    } else {
      setRecipients([]);
    }
  }, [centers, values.centers, values.userType]);

  const isSelected = useCallback(
    (recipient: IRecipient) =>
      (values.recipients ?? []).some((r: any) => r.id === recipient.id),
    [values.recipients]
  );

  useEffect(() => {
    if ((values.centers, values.userType)) {
      getRecipients();
    }
  }, [getRecipients, values.centers, values.userType]);

  useEffect(() => {
    getCenters();
  }, [getCenters]);

  return (
    <Stack
      direction="column"
      spacing={1}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={5}
          xs={12}
        >
          <Paper sx={{ p: 4, minHeight: '100%' }}>
            <Stack
              direction="column"
              spacing={3}
            >
              <Stack>
                <SwitchElement
                  label="Notificações Push"
                  name="push.enabled"
                />
                <SwitchElement
                  label="Alerta Importante"
                  name="important.enabled"
                />
              </Stack>
              <Divider />
              <AutocompleteElement
                autocompleteProps={{
                  ChipProps: { size: 'small' },
                  onChange(_, value) {
                    if (value === 'all') {
                      setTimeout(() => {
                        form.setValue('centers', ['all']);
                      }, 400);
                    }
                  },
                  noOptionsText: 'Nenhuma opção encontrada',
                  loadingText: 'Carregando...',
                  renderTags(tags, getTagProps) {
                    return (
                      <Stack
                        direction="row"
                        flexWrap="wrap"
                        spacing={1}
                      >
                        {tags.map((value, key) => (
                          <Chip
                            label={value.label}
                            size="small"
                            {...getTagProps({ index: key })}
                          />
                        ))}
                      </Stack>
                    );
                  },
                }}
                label="Centro"
                multiple
                name="centers"
                options={[{ id: 'all', label: 'Todos' }, ...centers].filter(
                  (c) => {
                    if (
                      centerOptions.length > 0 &&
                      ((centerOptions.some((cc) => cc.id === 'all') &&
                        c.id !== 'all') ||
                        (!centerOptions.some((cc) => cc.id === 'all') &&
                          c.id === 'all'))
                    ) {
                      return false;
                    }

                    return !(centerOptions ?? []).some((cc) => cc.id === c.id);
                  }
                )}
              />
              <SelectElement
                label="Destinatários"
                name="userType"
                options={USER_TYPE_OPTIONS}
              />
              <TextFieldElement
                label="Titulo"
                name="title"
              />
              <Collapse
                in={!!values.important?.enabled}
                unmountOnExit
              >
                <Switch
                  currentActive={values.important?.type ?? 'text'}
                  onChange={(value) => {
                    form.setValue('important.type', value);
                    if (value === 'image') {
                      form.setValue('message', null);
                    }
                    if (value === 'text') {
                      form.setValue('important.image', null);
                    }
                  }}
                  options={[
                    { label: 'Texto', value: 'text' },
                    { label: 'Imagem', value: 'image' },
                  ]}
                />
              </Collapse>
              <Collapse
                in={(values.important?.type ?? 'text') === 'text'}
                unmountOnExit
              >
                <TextFieldElement
                  helperText="Max. 250 caracteres"
                  label="Mensagem"
                  maxRows={5}
                  multiline
                  name="message"
                  rows={3}
                />
              </Collapse>
              <Collapse
                in={
                  values.important.enabled && values.important?.type === 'image'
                }
                unmountOnExit
              >
                <ImageInput
                  name="important.image"
                  sx={{ height: 120 }}
                />
              </Collapse>
              <Collapse
                in={!values?.important?.enabled}
                unmountOnExit
              >
                <Stack spacing={2}>
                  <TextFieldElement
                    label="URL de link"
                    name="url"
                  />
                  <Stack
                    direction="row"
                    spacing={1}
                  >
                    <Button
                      component="label"
                      startIcon={<AttachFile />}
                      sx={{ flex: 1 }}
                      variant={values.attachment ? 'outlined' : 'text'}
                    >
                      <input
                        hidden
                        multiple={false}
                        onChange={(event) => {
                          form.setValue('attachment', event.target.files?.[0]);
                        }}
                        type="file"
                      />
                      {values.attachment?.name
                        ? values.attachment?.name
                        : 'Adicionar Anexo'}
                    </Button>
                    {!!values.attachment && (
                      <IconButton
                        color="error"
                        onClick={() => {
                          form.setValue('attachment', null);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Stack>
                </Stack>
              </Collapse>
              <Collapse
                in={!!values.important?.enabled}
                unmountOnExit
              >
                <Stack spacing={1}>
                  <InputButtonControl
                    defaultValues={{ label: 'OK' }}
                    disabledDelete={false}
                    names={{
                      label: 'important.ok.label',
                      link: 'important.ok.link',
                    }}
                  />
                  <InputButtonControl
                    defaultValues={{ label: 'Cancelar' }}
                    names={{
                      label: 'important.cancel.label',
                      link: 'important.cancel.link',
                    }}
                  />
                  <Button
                    disabled={
                      !values.title ||
                      (values.important.type === 'text' && !values.message) ||
                      (values.important.type === 'image' &&
                        !values.important.image)
                    }
                    onClick={() => {
                      setOpenImportantAlertPreview(true);
                    }}
                    startIcon={<Visibility />}
                  >
                    Visualizar alerta
                  </Button>
                  <ImportantAlertPreview
                    dialogProps={{
                      open: openImportantAlertPreview,
                      onClose() {
                        setOpenImportantAlertPreview(false);
                      },
                    }}
                  />
                </Stack>
              </Collapse>
              <Box flexGrow={1} />
            </Stack>
          </Paper>
        </Grid>
        {recipients.length > 0 && (
          <Grid
            item
            md={7}
            xs={12}
          >
            <Paper
              sx={{
                p: 4,
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Stack
                direction="column"
                spacing={2}
                sx={{ flexGrow: 1 }}
              >
                <FormLabel
                  sx={{ fontSize: 20, fontWeight: 'normal', color: 'grey.600' }}
                >
                  Destinatários{' '}
                  <Chip
                    label={form.watch('recipients', [])?.length ?? 0}
                    size="small"
                  />
                </FormLabel>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                >
                  <Button
                    disabled={recipients.length === 0}
                    onClick={() => {
                      if ((values.recipients ?? []).length > 0) {
                        form.setValue('recipients', []);
                      } else {
                        setAllRecipients();
                      }
                    }}
                    size="small"
                  >
                    {(values.recipients ?? []).length > 0
                      ? 'LIMPAR SELECIONADOS'
                      : 'SELECIONAR TUDO'}
                  </Button>
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    spacing={1}
                  >
                    {centerOptions.map(({ id, label }) => (
                      <Chip
                        key={id}
                        label={id === 'all' ? 'Todos' : `Centro ${label}`}
                        size="small"
                      />
                    ))}
                  </Stack>
                </Stack>
                <Divider
                  flexItem
                  sx={{ marginTop: 1 }}
                />
                <Box
                  sx={{
                    flexGrow: 1,
                    flexBasis: 1,
                    overflow: 'auto',
                  }}
                >
                  {(recipientsPaginated.data ?? []).map((recipient) => (
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      key={recipient.id}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSelected(recipient)}
                            onChange={(_, checked) => {
                              const recipientIndex = (
                                values.recipients ?? []
                              ).findIndex((v: any) => v.id === recipient.id);
                              if (!checked) {
                                recipientForm.remove(recipientIndex);
                              } else {
                                recipientForm.append(recipient);
                              }
                            }}
                          />
                        }
                        label={recipient.name}
                      />
                      <Typography sx={{ color: 'grey.400' }}>
                        {{
                          provider: 'Produtor',
                          collaborator: 'Colaborador',
                          supervisor: 'Supervisor',
                        }[recipient.type] ?? 'Outro'}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
                {recipientsPaginated.totalPages > 1 && (
                  <Stack
                    alignItems="center"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    <Pagination
                      count={recipientsPaginated.totalPages}
                      onChange={(_, p: number) => {
                        setPage(p);
                      }}
                      page={recipientsPaginated.page}
                    />
                  </Stack>
                )}
                <Stack
                  direction="row"
                  spacing={1}
                >
                  <Button
                    color="error"
                    component={Link}
                    fullWidth
                    size="large"
                    to=".."
                    type="submit"
                    variant="contained"
                  >
                    CANCELAR
                  </Button>
                  <Button
                    disabled={!form.formState.isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    ENVIAR NOTIFICAÇÃO
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};
export default NotificationForm;
