import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Assessment } from '@mui/icons-material';
import {
  Box,
  Chip,
  Collapse,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import {
  addHours,
  endOfMonth,
  format,
  parse,
  parseISO,
  startOfMonth,
} from 'date-fns';
import { orderBy } from 'lodash';

import Content from '../../../../components/layouts/content';
import { useAuth } from '../../../../contexts/auth';
import { Slot } from '../../../../contexts/slot';
import resources from '../../../../resources';
import { IPriceOverview } from '../../../../resources/price';
import { IQualityOverview } from '../../../../resources/quality';
import {
  IVolumeDayOverview,
  IVolumeOverview,
} from '../../../../resources/volume';
import DashboardNavbarFilter from '../../components/dashboard-navbar-filter';
import { LoadingChart } from '../../components/loading-chart';
import PriceChart, { IPriceValueChart } from '../../components/price-chart';
import QualityChart, {
  IQualityValueChart,
} from '../../components/quality-chart';
import ReportNumbersCard from '../../components/report-numbers-card';
import VolumeChart, { IVolumeValueChart } from '../../components/volume-chart';
import VolumeDayChart, {
  IVolumeDayValuesChart,
} from '../../components/volume-day-chart';

const DEFAULT_PRICE_OVERVIEW: IPriceOverview = {
  priceData: {
    priceAverage: 0,
    priceVariation: 0,
  },
  graphicCodCenter: [],
  graphicMonth: [],
};

const DEFAULT_VOLUME_OVERVIEW: IVolumeOverview = {
  volumeData: {
    totalVolume: 0,
    volumeVariation: 0,
  },
  graphicMonth: [],
};

const DEFAULT_QUALITY_OVERVIEW: IQualityOverview = {
  qualityData: {
    averageFat: 0,
    averageProt: 0,
    averageCbt: 0,
    averageCcs: 0,
    averageEst: 0,
    averageEsd: 0,
  },
  qualityVariation: {
    fatVariation: 0,
    protVariation: 0,
    cbtVariation: 0,
    ccsVariation: 0,
    estVariation: 0,
    esdVariation: 0,
  },
  patternIndicator: {
    fatMin: 0,
    protMin: 0,
    cbtMax: 0,
    ccsMax: 0,
    estMin: 0,
    esdMin: 0,
  },
};

const EmptyChart = () => (
  <Stack
    alignItems="center"
    direction="row"
    justifyContent="center"
    spacing={1}
  >
    <Assessment sx={{ color: 'grey.300', fontSize: 80 }} />
    <Stack>
      <Typography sx={{ color: 'grey.500', fontSize: 18 }}>Ops!</Typography>
      <Typography sx={{ color: 'grey.500', fontSize: 14 }}>
        Nenhum dado para exibir
      </Typography>
    </Stack>
  </Stack>
);

const DefaultDashboard: React.FC = () => {
  const auth = useAuth();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const [priceOverview, setPriceOverview] = useState<IPriceOverview>(
    DEFAULT_PRICE_OVERVIEW
  );

  const [volumeOverview, setVolumeOverview] = useState<IVolumeOverview>(
    DEFAULT_VOLUME_OVERVIEW
  );

  const [volumeDayOverview, setVolumeDayOverview] = useState<
    IVolumeDayOverview | undefined
  >();

  const [qualityOverview, setQualityOverview] = useState<IQualityOverview>(
    DEFAULT_QUALITY_OVERVIEW
  );
  const [tabIndex, setTabIndex] = useState(0);
  const [compareCenters, setCompareCenters] = useState(false);

  const filterParams = useMemo(
    () => ({
      supervisors: searchParams.has('supervisors')
        ? searchParams.getAll('supervisors')
        : [],
      toDate: searchParams.has('toDate')
        ? parseISO(searchParams.get('toDate')!)
        : endOfMonth(new Date()),
      fromDate: searchParams.has('fromDate')
        ? parseISO(searchParams.get('fromDate')!)
        : startOfMonth(new Date()),
      centers: searchParams.has('centers')
        ? searchParams.getAll('centers')
        : (auth.user?.centers ?? []).map(({ id }) => id),
    }),
    [auth.user?.centers, searchParams]
  );

  const itsAllCenters = useMemo(() => {
    const selected = filterParams.centers;
    return (auth.user?.centers ?? [])
      .map(({ id }) => id)
      .every((c) => selected?.includes(c));
  }, [auth.user?.centers, filterParams.centers]);

  const itsClosedMonth = useMemo(() => {
    if (
      format(filterParams.fromDate, 'yyyy-MM-dd') ===
        format(startOfMonth(new Date()), 'yyyy-MM-dd') &&
      format(filterParams.toDate, 'yyyy-MM-dd') ===
        format(endOfMonth(new Date()), 'yyyy-MM-dd')
    )
      return true;

    return false;
  }, [filterParams.fromDate, filterParams.toDate]);

  const priceChartValues = useMemo<{
    max: number;
    values: IPriceValueChart[];
  }>(() => {
    if (!compareCenters) {
      const max =
        Math.max(...priceOverview.graphicMonth.map(({ average }) => +average)) +
        1;

      return {
        values: orderBy(
          priceOverview.graphicMonth.map(({ year, month, average }) => ({
            name: `${format(
              parse(`${month}`, 'M', new Date()),
              'MMMM'
            )}/${year}`,
            date: parse(`${year}-${month}`, 'yyyy-M', new Date()),
            value: average,
            max,
          })),
          ['date'],
          ['asc']
        ),
        max,
      };
    }

    const max =
      Math.max(
        ...priceOverview.graphicCodCenter.map(({ average }) => +average)
      ) + 1;

    return {
      values: priceOverview.graphicCodCenter.map(({ codCenter, average }) => ({
        name: `${codCenter}`,
        value: average,
        max,
      })),
      max,
    };
  }, [
    compareCenters,
    priceOverview.graphicCodCenter,
    priceOverview.graphicMonth,
  ]);

  const volumeChartValues = useMemo<{
    max: number;
    values: IVolumeValueChart[];
  }>(() => {
    const max =
      Math.max(
        ...volumeOverview.graphicMonth.map(({ totalVolume }) => +totalVolume)
      ) + 1;

    return {
      values: orderBy(
        volumeOverview.graphicMonth.map(({ year, month, totalVolume }) => ({
          name: `${format(parse(`${month}`, 'M', new Date()), 'MMMM')}/${year}`,
          date: parse(`${year}-${month}`, 'yyyy-M', new Date()),
          value: totalVolume,
          max,
        })),
        ['date'],
        'asc'
      ),
      max,
    };
  }, [volumeOverview.graphicMonth]);

  const volumeDayChartValues = useMemo<IVolumeDayValuesChart>(() => {
    const max =
      Math.max(
        ...(volumeDayOverview?.graphicDay?.map(
          ({ totalVolume }) => +totalVolume
        ) ?? [])
      ) + 1;

    const items =
      volumeDayOverview?.graphicDay.map(({ date, totalVolume }) => ({
        name: `${format(addHours(parseISO(`${date}`), 3), 'dd/MMMM')}`,
        value: totalVolume,
        max,
      })) ?? [];

    return {
      items,
      max,
    };
  }, [volumeDayOverview?.graphicDay]);

  const qualityChartValues = useMemo<IQualityValueChart>(
    () => ({
      fat: {
        name: 'g/100g',
        value: qualityOverview?.qualityData?.averageFat,
        max: 5,
        average: qualityOverview.patternIndicator.fatMin,
      },
      protein: {
        name: 'g/100g',
        value: qualityOverview?.qualityData?.averageProt,
        max: 5,
        average: qualityOverview.patternIndicator.protMin,
      },
      cbt: {
        name: 'UFC/ml',
        value: qualityOverview?.qualityData?.averageCbt,
        max: 500,
        average: qualityOverview.patternIndicator.cbtMax,
      },
      ccs: {
        name: 'CS/ml',
        value: qualityOverview?.qualityData?.averageCcs,
        max: 500,
        average: qualityOverview.patternIndicator.ccsMax,
      },
      est: {
        name: 'g/100g',
        value: qualityOverview?.qualityData?.averageEst,
        max: 15,
        average: qualityOverview.patternIndicator.estMin,
      },
      esd: {
        name: 'g/100g',
        value: qualityOverview?.qualityData?.averageEsd,
        max: 10,
        average: qualityOverview.patternIndicator.esdMin,
      },
    }),
    [
      qualityOverview.patternIndicator.cbtMax,
      qualityOverview.patternIndicator.ccsMax,
      qualityOverview.patternIndicator.esdMin,
      qualityOverview.patternIndicator.estMin,
      qualityOverview.patternIndicator.fatMin,
      qualityOverview.patternIndicator.protMin,
      qualityOverview?.qualityData?.averageCbt,
      qualityOverview?.qualityData?.averageCcs,
      qualityOverview?.qualityData?.averageEsd,
      qualityOverview?.qualityData?.averageEst,
      qualityOverview?.qualityData?.averageFat,
      qualityOverview?.qualityData?.averageProt,
    ]
  );

  const getParams = (query: {
    supervisors: string[] | null;
    fromDate: Date | null;
    toDate: Date | null;
    centers: string[] | null;
  }) => ({
    supervisors: query.supervisors,
    fromDate: query.fromDate
      ? format(new Date(query.fromDate), 'yyyy-MM-dd')
      : format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    toDate: query.toDate
      ? format(new Date(query.toDate), 'yyyy-MM-dd')
      : format(endOfMonth(new Date()), 'yyyy-MM-dd'),
    codCenters: query.centers ?? [],
  });

  const fetchPriceDataOverview = useCallback(async () => {
    const params = getParams(filterParams);
    const { supervisors } = params;

    const response = await resources.use('price').getPriceDataOverview({
      supervisors: supervisors ?? [],
      fromDate: params.fromDate,
      toDate: params.toDate,
      centers: params.codCenters
        ? params.codCenters
        : (auth.user?.centers ?? []).map(({ id }) => id) ?? [],
    });

    if (response.status === 'OK' && response.payload) {
      setPriceOverview(response.payload);
    } else {
      setPriceOverview(DEFAULT_PRICE_OVERVIEW);
    }
  }, [auth.user?.centers, filterParams]);

  const fetchVolumeDataOverview = useCallback(async () => {
    const params = getParams(filterParams);
    const { supervisors } = params;

    const response = await resources.use('volume').getVolumeDataOverview({
      supervisors: supervisors ?? [],
      fromDate: params.fromDate,
      toDate: params.toDate,
      centers: params.codCenters
        ? params.codCenters
        : (auth.user?.centers ?? []).map(({ id }) => id) ?? [],
    });

    if (response.status === 'OK' && response.payload) {
      setVolumeOverview(response.payload);
    } else {
      setVolumeOverview(DEFAULT_VOLUME_OVERVIEW);
    }
  }, [auth.user?.centers, filterParams]);

  const fetchQualityDataOverview = useCallback(async () => {
    const params = getParams(filterParams);
    const { supervisors } = params;

    const response = await resources.use('quality').qualityDataOverview({
      supervisors: supervisors ?? [],
      fromDate: params.fromDate,
      toDate: params.toDate,
      centers: params.codCenters
        ? params.codCenters
        : (auth.user?.centers ?? []).map(({ id }) => id) ?? [],
    });

    if (response.status === 'OK' && response.payload) {
      setQualityOverview(response.payload);
    } else {
      setQualityOverview(DEFAULT_QUALITY_OVERVIEW);
    }
  }, [auth.user?.centers, filterParams]);

  const fetchVolumeDayOverview = useCallback(async () => {
    const supervisors = searchParams.has('supervisors')
      ? searchParams.getAll('supervisors')
      : [];
    const centers = searchParams.has('centers')
      ? searchParams.getAll('centers')
      : auth.user?.centers.map(({ id }) => id) ?? [];

    const startDate = startOfMonth(new Date());
    const endDate = endOfMonth(new Date());

    const response = await resources.use('volume').getVolumeGraphicDayOverview({
      toDate: format(endDate, 'yyyy-MM-dd'),
      fromDate: format(startDate, 'yyyy-MM-dd'),
      centers,
      supervisors,
    });

    if (response.status === 'OK') {
      setVolumeDayOverview(response.payload ?? undefined);
    }
  }, [auth.user?.centers, searchParams]);

  useEffect(() => {
    if (itsClosedMonth) {
      fetchVolumeDayOverview();
    }
  }, [fetchVolumeDayOverview, itsClosedMonth]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetchPriceDataOverview(),
      fetchVolumeDataOverview(),
      fetchQualityDataOverview(),
    ]).finally(() => {
      setLoading(false);
    });
  }, [
    fetchPriceDataOverview,
    fetchVolumeDataOverview,
    fetchQualityDataOverview,
  ]);

  return (
    <>
      <Slot name="navbar">
        <DashboardNavbarFilter />
      </Slot>
      <Content
        title={
          <Stack
            alignItems="center"
            direction="row"
            spacing={1}
          >
            <span>Visão Geral</span>
            <Chip
              color="success"
              label={
                !itsAllCenters
                  ? searchParams.getAll('centers').length > 1
                    ? `${
                        tabIndex === 1 ? 'Acumulado' : 'Média'
                      } dos Centros Selecionados`
                    : 'Acumulado do Centro Selecionado'
                  : `${
                      tabIndex === 1 ? 'Acumulado' : 'Média'
                    } de todos os Centros`
              }
              size="small"
              variant="outlined"
            />
            {searchParams.has('supervisors') && (
              <Chip
                color="success"
                label={
                  searchParams.getAll('supervisors').length > 1
                    ? 'Supervisores Selecionados'
                    : 'Supervisor Selecionado'
                }
                size="small"
                variant="outlined"
              />
            )}
            {itsClosedMonth && (
              <Chip
                color="success"
                label="Último mês fechado"
                size="small"
                variant="outlined"
              />
            )}
            {!itsClosedMonth &&
              (searchParams.has('toDate') || searchParams.has('fromDate')) && (
                <Chip
                  color="success"
                  label="Período Selecionado"
                  size="small"
                  variant="outlined"
                />
              )}
          </Stack>
        }
      >
        <Stack
          direction="column"
          spacing={3}
        >
          <Stack>
            <Grid container>
              <Grid
                item
                md={7}
                xs={12}
              >
                <ReportNumbersCard
                  items={[
                    {
                      title: 'Gordura',
                      value: qualityOverview?.qualityData?.averageFat ?? 0,
                      ...(qualityOverview?.qualityVariation?.fatVariation !==
                      null
                        ? {
                            compare: {
                              value:
                                qualityOverview?.qualityVariation
                                  ?.fatVariation ?? 0,
                              type: 'percentage',
                            },
                          }
                        : {}),
                    },
                    {
                      title: 'Proteína',
                      value: qualityOverview?.qualityData?.averageProt ?? 0,
                      ...(qualityOverview?.qualityVariation?.protVariation !==
                      null
                        ? {
                            compare: {
                              value:
                                qualityOverview.qualityVariation
                                  ?.protVariation ?? 0,
                              type: 'percentage',
                            },
                          }
                        : {}),
                    },
                    {
                      title: 'CBT',
                      value: qualityOverview?.qualityData?.averageCbt ?? 0,
                      valueFormatOption: {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      },
                      ...(qualityOverview?.qualityVariation?.cbtVariation !==
                      null
                        ? {
                            compare: {
                              value:
                                qualityOverview?.qualityVariation
                                  ?.cbtVariation ?? 0,
                              type: 'percentage',
                            },
                          }
                        : {}),
                    },
                    {
                      title: 'CCS',
                      value: qualityOverview?.qualityData?.averageCcs ?? 0,
                      valueFormatOption: {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      },
                      ...(qualityOverview?.qualityVariation?.ccsVariation !==
                      null
                        ? {
                            compare: {
                              value:
                                qualityOverview?.qualityVariation
                                  ?.ccsVariation ?? 0,
                              type: 'percentage',
                            },
                          }
                        : {}),
                    },
                    {
                      title: 'EST',
                      value: qualityOverview?.qualityData?.averageEst ?? 0,
                      ...(qualityOverview?.qualityVariation?.estVariation !==
                      null
                        ? {
                            compare: {
                              value:
                                qualityOverview?.qualityVariation
                                  ?.estVariation ?? 0,
                              type: 'percentage',
                            },
                          }
                        : {}),
                    },
                    {
                      title: 'ESD',
                      value: qualityOverview?.qualityData?.averageEsd ?? 0,
                      ...(qualityOverview?.qualityVariation?.esdVariation !==
                      null
                        ? {
                            compare: {
                              value:
                                qualityOverview?.qualityVariation
                                  ?.esdVariation ?? 0,
                              type: 'percentage',
                            },
                          }
                        : {}),
                    },
                  ]}
                  loading={loading}
                  title="Qualidade do Leite"
                />
              </Grid>
              <Grid
                item
                md={2.5}
                xs={12}
              >
                <ReportNumbersCard
                  items={[
                    {
                      title: '',
                      value: volumeOverview.volumeData.totalVolume,
                      suffix: 'L',
                      ...(volumeOverview.volumeData.volumeVariation !== null
                        ? {
                            compare: {
                              value:
                                volumeOverview.volumeData.volumeVariation ?? 0,
                              type: 'percentage',
                            },
                          }
                        : {}),
                    },
                  ]}
                  loading={loading}
                  title="Volume Coletado"
                />
              </Grid>
              <Grid
                item
                md={2.5}
                xs={12}
              >
                <ReportNumbersCard
                  items={[
                    {
                      title: '',
                      value: priceOverview.priceData.priceAverage ?? 0,
                      prefix: 'R$',
                      ...(priceOverview.priceData.priceVariation !== null
                        ? {
                            compare: {
                              value:
                                priceOverview.priceData.priceVariation ?? 0,
                              type: 'percentage',
                            },
                          }
                        : {}),
                    },
                  ]}
                  loading={loading}
                  title="Preço"
                />
              </Grid>
            </Grid>
            {(qualityOverview?.qualityVariation?.fatVariation ?? null) !==
              null && (
              <FormHelperText>
                *Valor comparado ao mesmo período no ano anterior.
              </FormHelperText>
            )}
          </Stack>
          <Paper>
            <Stack
              alignItems="center"
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.palette.divider}`,
              })}
            >
              <Box sx={{ flex: 1 }} />
              <Tabs
                centered
                indicatorColor="secondary"
                onChange={(_, i) => setTabIndex(i)}
                value={tabIndex}
              >
                <Tab label="Qualidade" />
                <Tab label="Volume" />
                <Tab label="Preço" />
              </Tabs>
              <Box
                display="flex"
                flex={1}
                justifyContent="flex-end"
                px={2}
              >
                <Collapse
                  in={
                    tabIndex === 2 && priceOverview.graphicCodCenter.length > 1
                  }
                  orientation="horizontal"
                  unmountOnExit
                >
                  <FormControlLabel
                    control={<Switch size="small" />}
                    label="Comparar Centros"
                    onChange={(_, value) => {
                      setCompareCenters(!!value);
                    }}
                    sx={{ mr: 0, whiteSpace: 'nowrap' }}
                    value={compareCenters}
                  />
                </Collapse>
              </Box>
            </Stack>
            <Box sx={{ p: 3, position: 'relative' }}>
              <Collapse
                in={tabIndex === 0}
                unmountOnExit
              >
                {loading ? (
                  <LoadingChart />
                ) : (
                  <QualityChart values={qualityChartValues} />
                )}
              </Collapse>
              <Collapse
                in={tabIndex === 1}
                unmountOnExit
              >
                {loading ? (
                  <LoadingChart />
                ) : !itsClosedMonth &&
                  volumeOverview.graphicMonth.length > 0 ? (
                  <VolumeChart
                    chip={{ label: 'Volume em Litro' }}
                    max={volumeChartValues.max}
                    values={volumeChartValues.values}
                  />
                ) : itsClosedMonth ? (
                  <VolumeDayChart values={volumeDayChartValues} />
                ) : (
                  <EmptyChart />
                )}
              </Collapse>
              <Collapse
                in={tabIndex === 2}
                unmountOnExit
              >
                {loading ? (
                  <LoadingChart />
                ) : priceOverview.graphicMonth.length > 0 ||
                  priceOverview.graphicCodCenter.length > 0 ? (
                  <PriceChart
                    chip={
                      !compareCenters
                        ? { label: 'Preço Pago por Litro' }
                        : undefined
                    }
                    max={priceChartValues.max}
                    values={priceChartValues.values}
                  />
                ) : (
                  <EmptyChart />
                )}
              </Collapse>
            </Box>
          </Paper>
        </Stack>
      </Content>
    </>
  );
};

export default DefaultDashboard;
